<template>
    <div
        class="hits-table__wrapper"
        v-if="analyticsCollects.length"
        v-intersect="{onChange: handleIntersection}"
    >
        <b-card no-body>
            <b-card-header>Hits Collected</b-card-header>
            <b-card-body>
                <div class="stats-filter-row">
                    <div class="stats-filter-row__col stats-filter-row__col-buttons">
                        <span v-b-tooltip.hover title="Refresh">
                            <i
                                @click="fetchTable"
                                class="icon-reload stats-filter-row__icon pointer-action"
                            />
                        </span>
                    </div>
                    <div class="stats-filter-row__col stats-filter-row__col-flex">
                        <DateRangePicker
                            ref="picker"
                            :appendToBody="true"
                            opens="left"
                            v-model="dateRange"
                            @update="fetchTable"
                            class="stats-filter-row__daterange-picker"
                        ></DateRangePicker>
                    </div>
                </div>
                <wit-table
                    :items="hits.items"
                    :fields="hits.fields"
                    :striped="true"
                    paginated
                    :default-per-page="10"
                    :show-per-page-select="true"
                    :busy="hits.busy"
                >
                    <template v-slot:cell(hits)="{item}">{{ item.hits.toLocaleString('pl-PL') }}</template>
                    <template v-slot:custom-foot v-if="hits.items.length > 1 && !hits.busy">
                        <b-tr>
                            <b-th colspan="1" />
                            <b-th colspan="1">Total</b-th>
                            <b-th colspan="1">{{ sumHits.toLocaleString('pl-PL') }}</b-th>
                        </b-tr>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {IntersectDirective} from 'vue-intersect-directive'

import WitTable from '@/components/WitTable.vue'

import {dashboardMixin} from '@/mixins/dashboardMixin'

const d3format = require('d3-time-format')

export default {
    components: {
        DateRangePicker,
        WitTable,
    },
    directives: {
        intersect: IntersectDirective,
    },
    mixins: [dashboardMixin],
    data() {
        return {
            dateRange: {
                startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 12, 0, 0),
                endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 12, 0, 0),
            },
            hits: {
                items: [],
                fields: [
                    {
                        key: 'dataset',
                    },
                    {
                        key: 'tableId',
                        label: 'Table',
                    },
                    {
                        key: 'hits',
                    },
                ],
                busy: false,
            },
            fetchTimeout: null,
            requested: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            collect: 'collect/all',
        }),
        analyticsCollects() {
            return [
                ...new Set(
                    this.collect
                        .filter(el => el.type === 'User Behaviour' && el.subtype === 'WitCloud Stream')
                        .map(el => el.name)
                ),
            ].map(el => ({id: el, label: el}))
        },
        sumHits() {
            return this.hits.items.reduce((acc, curr) => acc + curr.hits, 0)
        },
    },
    mounted() {
        this.mounted = true
    },
    methods: {
        async handleIntersection(value) {
            if (value && !this.requested && this.mounted) {
                this.hits.busy = true
                await this.fetchTable()
            }
        },

        async fetchTable() {
            if (!this.analyticsCollects.length) {
                return
            }

            this.hits.busy = true
            this.requested = true
            try {
                const hitsRequest = await this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/dashboard/getHits`,
                    {
                        witcloudProjectId: this.activeProject.id,
                        startDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.startDate),
                        endDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.endDate),
                    },
                    {
                        cancelToken: this.cancelSource.token,
                    }
                )

                this.hits.items =
                    hitsRequest.data.data.map(item => ({
                        ...item,
                        lastHitTimeFormatted: d3format.timeFormat('%Y-%m-%d %H:%M:%S.%LZ')(new Date(item.lastHitTime)),
                    })) || []

                this.hits.busy = false
            } catch (e) {
                if (this.axios.isCancel(e)) {
                    return
                } else {
                    this.hits.busy = false
                    this.$errorHandler.report(e, 'Could not fetch hits')
                }
            }
        },
        fetchHitsTimeout() {
            if (this.fetchTimeout) {
                clearTimeout(this.fetchTimeout)
                this.fetchTimeout = null
            }
            this.fetchTimeout = setTimeout(() => this.fetchTable(), 1500)
        },
    },
}
</script>

<style lang="scss">
.hits-table__toggle-details {
    margin-left: 15px;
}
.hits-table__details {
    padding: 0 1rem;
    * {
        background-color: #e4e5e6;
    }
    .hits-table__details-headers {
        th {
            text-align: center;
            padding-right: 3rem;
        }
    }
}
</style>
