<template>
    <div class="animated fadeIn">
        <div class="dashboard">
            <UrlCard />
            <ResourcesCard />
            <HitsCard />
            <StatisticsCard />
            <!-- <ResourcesMonitoring /> -->
            <!-- <AnalyticsComparisonCard /> -->
        </div>
    </div>
</template>

<script>
// import AnalyticsComparisonCard from './AnalyticsComparisonCard.vue'
import HitsCard from './HitsCard.vue'
import ResourcesCard from './ResourcesCard.vue'
// import ResourcesMonitoring from './ResourcesMonitoring.vue'
import StatisticsCard from './StatisticsCard.vue'
import UrlCard from './UrlCard.vue'

export default {
    components: {
        // AnalyticsComparisonCard,
        HitsCard,
        ResourcesCard,
        // ResourcesMonitoring,
        StatisticsCard,
        UrlCard,
    },
}
</script>

<style lang="scss">
.dashboard-header {
    font-weight: 600;
    font-size: 0.9rem;
    span {
        color: #7f7f7f;
        padding: 0.5rem 0.5rem 0.5rem 0;
        border-bottom: 1px solid #7f7f7f;
    }
    margin-bottom: 1rem;
}
.dashboard {
    margin-bottom: 100px;
    .card {
        .card-header {
            // background: rgba(0, 0, 0, 0.08);
            font-weight: 600;
        }
    }
    .stats-filter-row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 0 -0.5rem;
        margin-bottom: 10px;

        .stats-filter-row__col {
            padding: 0 0.5rem;
            display: inline-block;
            min-width: 150px;
            flex-grow: 2;
        }
        .stats-filter-row__col-treeselect {
            flex-grow: 3;
        }
        .vue-daterange-picker {
            float: right;
            min-width: 250px;
        }
        .stats-filter-row__icon {
            color: #7f7f7f;
            font-weight: 600;
            padding: 0;
            font-size: 0.95rem;
            padding-right: 5px;
        }
        .row {
            margin: 0 -0.5rem;
        }
        .vue-treeselect {
            // margin-bottom: -1px;
            * {
                font-size: 0.76562rem;
                text-transform: none;
            }
            .vue-treeselect__control {
                height: auto;
                width: auto;
                padding-left: 0;
                border: 1px solid #e8e8e8;
                border-radius: 8px;
                border-collapse: separate;
            }
            .vue-treeselect__multi-value {
                min-height: calc(1.5em + 0.5rem);
            }
            .vue-treeselect__x-container {
                display: none;
                visibility: hidden;
            }
            .vue-treeselect__control-arrow-container {
                padding: 4px 2px 0 3px;
            }
            .vue-treeselect__multi-value-item-container {
                padding-top: 3px;
                .vue-treeselect__multi-value-item {
                    padding: 0;
                }
            }
            .vue-treeselect__input-container {
                padding-left: 0;
                input {
                    height: calc(1.5em + 0.5rem);
                    padding: 0;
                    line-height: 1.5;
                }
            }
            .vue-treeselect__multi-value {
                margin-bottom: 0;
                padding-left: 0.5rem;
                line-height: 1.5;
            }
            .vue-treeselect__placeholder,
            .vue-treeselect__single-value {
                height: calc(1.5em + 0.5rem);
                font-size: 0.76562rem;
                margin: 0;
                padding: 0.25rem 0.5rem;
                line-height: 1.5;
            }
        }
        .stats-filter-row__col-flex {
            display: inline-flex;
            justify-content: flex-end;
            .stats-filter-row__per-page {
                width: 100px;
                order: 1;
                margin-right: 1rem;
            }
            .stats-filter-row__daterange-picker {
                order: 2;
            }
        }
        .stats-filter-row__col-unit {
            .stats-filter-row__unit-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                font-size: 0.7rem;
                color: #7f7f7f;
                line-height: 1.5;
                .stats-filter-row__unit {
                    margin-left: 10px;
                    width: 100px;
                }
            }
        }
    }
    .resources-card__container-row {
        margin: 0 -0.5rem;
        .resources-card__container-col {
            padding: 0 0.5rem;
            .resources-card {
                height: calc(100% - 1.5rem);
                .card-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .resources-card__header-label {
                        i {
                            margin-right: 3px;
                        }
                    }
                    .resources-card__router-link {
                        float: right;
                    }
                }
                .resource-card__view-all-wrapper {
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    font-size: 0.85rem;
                }
                tfoot {
                    th {
                        border-top: none;
                    }
                }
            }
        }
    }
    .stats-card {
        padding-bottom: 25px;
    }
    .stats-chart-bar {
        height: 300px;
        margin-bottom: 50px;
    }
}
</style>
