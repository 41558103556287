<template>
    <div v-intersect="{onChange: handleIntersection}">
        <b-row class="resources-card__container-row">
            <b-col md="4" class="resources-card__container-col">
                <b-card class="resources-card" no-body v-bind:class="{'resources-card__nonscrollable': allHidden}">
                    <b-card-header>
                        <span class="resources-card__header-label">
                            <i class="icon-equalizer" />
                            Collect
                        </span>
                        <project-router-link to="/collect/source" class="resources-card__router-link">
                            <b-button :disabled="isViewer" class="text-nowrap" variant="primary" size="sm">
                                <i class="icon-plus mr-1"></i>Create New
                            </b-button>
                        </project-router-link>
                    </b-card-header>
                    <b-card-body>
                        <wit-table
                            :items="resources.collectAggregate"
                            :fields="resources.fields"
                            :striped="true"
                            :busy="resources.busy"
                        >
                            <template v-slot:cell(subtype)="{item}">
                                <img
                                    v-if="types[`${item.type} ${item.subtype}`]"
                                    :src="types[`${item.type} ${item.subtype}`].img"
                                    class="resources-card__img"
                                />
                                {{ item.subtype }}
                            </template>
                            <template v-slot:cell(actions)="{item}">
                                <span v-b-tooltip.hover title="Create New" v-if="item.link">
                                    <project-router-link :to="item.link">
                                        <i class="fa fa-plus-circle pointer-action" />
                                    </project-router-link>
                                </span>
                            </template>
                            <template v-slot:custom-foot>
                                <b-tr>
                                    <b-th colspan="4">
                                        <div class="resource-card__view-all-wrapper">
                                            <project-router-link to="/collect">
                                                <span v-b-tooltip.hover title="View All">
                                                    <i class="fa fa-angle-double-down pointer-action" />
                                                </span>
                                            </project-router-link>
                                        </div>
                                    </b-th>
                                </b-tr>
                            </template>
                        </wit-table>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col md="4" class="resources-card__container-col">
                <b-card class="resources-card" no-body>
                    <b-card-header>
                        <span class="resources-card__header-label">
                            <i class="icon-graph" />
                            Process
                        </span>
                        <project-router-link to="/process/source" class="resources-card__router-link">
                            <b-button :disabled="isViewer" class="text-nowrap" variant="primary" size="sm">
                                <i class="icon-plus mr-1"></i>Create New
                            </b-button>
                        </project-router-link>
                    </b-card-header>
                    <b-card-body>
                        <wit-table
                            :items="resources.processAggregate"
                            :fields="resources.fields"
                            :striped="true"
                            :busy="resources.busy"
                        >
                            <template v-slot:cell(subtype)="{item}">
                                <img
                                    v-if="types[`${item.type} ${item.subtype}`]"
                                    :src="types[`${item.type} ${item.subtype}`].img"
                                    class="resources-card__img"
                                />
                                {{ item.subtype }}
                            </template>
                            <template v-slot:cell(actions)="{item}">
                                <span v-b-tooltip.hover title="Create New" v-if="item.link">
                                    <project-router-link :to="item.link">
                                        <i class="fa fa-plus-circle pointer-action" />
                                    </project-router-link>
                                </span>
                            </template>
                            <template v-slot:custom-foot>
                                <b-tr>
                                    <b-th colspan="4">
                                        <div class="resource-card__view-all-wrapper">
                                            <project-router-link to="/process">
                                                <span v-b-tooltip.hover title="View All">
                                                    <i class="fa fa-angle-double-down pointer-action" />
                                                </span>
                                            </project-router-link>
                                        </div>
                                    </b-th>
                                </b-tr>
                            </template>
                        </wit-table>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col md="4" class="resources-card__container-col">
                <b-card class="resources-card" no-body>
                    <b-card-header>
                        <span class="resources-card__header-label">
                            <i class="icon-note" />
                            Reports
                        </span>
                        <project-router-link to="/reports/queryjobs/create" class="resources-card__router-link">
                            <b-button :disabled="isViewer" class="text-nowrap" variant="primary" size="sm">
                                <i class="icon-plus mr-1"></i>Create New
                            </b-button>
                        </project-router-link>
                    </b-card-header>
                    <b-card-body>
                        <wit-table
                            :items="resources.reportAggregate"
                            :fields="resources.fields"
                            :striped="true"
                            :busy="resources.busy"
                        >
                            <template v-slot:cell(actions)="{item}">
                                <span v-b-tooltip.hover title="Create New" v-if="item.link">
                                    <project-router-link :to="item.link">
                                        <i class="fa fa-plus-circle pointer-action" />
                                    </project-router-link>
                                </span>
                            </template>
                            <template v-slot:custom-foot>
                                <b-tr>
                                    <b-th colspan="4">
                                        <div class="resource-card__view-all-wrapper">
                                            <project-router-link to="/reports/queryjobs/list">
                                                <span v-b-tooltip.hover title="View All">
                                                    <i class="fa fa-angle-double-down pointer-action" />
                                                </span>
                                            </project-router-link>
                                        </div>
                                    </b-th>
                                </b-tr>
                            </template>
                        </wit-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {IntersectDirective} from 'vue-intersect-directive'

import WitTable from '@/components/WitTable.vue'

import {fetchAll} from '@/shared/fetchAll.js'
import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'
import {reportTypes} from '@/shared/reportTypes'

import {dashboardMixin} from '@/mixins/dashboardMixin'

export default {
    components: {
        WitTable,
    },
    directives: {
        intersect: IntersectDirective,
    },
    mixins: [dashboardMixin],
    data() {
        return {
            resources: {
                collectTypes,
                processTypes,
                reportTypes,
                collectAggregate: [],
                processAggregate: [],
                reportAggregate: [],
                fields: ['type', 'subtype', 'count', 'actions'],
                busy: false,
                requested: false,
            },
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            collect: 'collect/all',
            process: 'process/all',
            report: 'report/all',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
        allHidden() {
            return this.$store.state.sidebar.collapsed && this.$store.state.aside.collapsed
        },
        types() {
            return [...collectTypes, ...processTypes].reduce(
                (acc, curr) => ({
                    ...acc,
                    [`${curr.type} ${curr.subtype}`]: {...curr, img: curr.img ? require(`@/assets/${curr.img}`) : null},
                }),
                {}
            )
        },
    },
    mounted() {
        this.mounted = true
    },
    methods: {
        async handleIntersection(value) {
            if (value && !this.requested && this.mounted) {
                this.fetchResources()
            }
        },

        async fetchResources() {
            this.resources.busy = true
            this.requested = true

            await fetchAll(this.$store, this.activeProject.id, this.cancelSource.token)
            this.resources.collectAggregate = this.calculateAggregate(this.resources.collectTypes, this.collect)
            this.resources.processAggregate = this.calculateAggregate(this.resources.processTypes, this.process)
            this.resources.reportAggregate = this.calculateAggregate(this.resources.reportTypes, this.report)

            this.resources.busy = false
        },

        calculateAggregate(types, resources) {
            const aggregate = types.map(el => ({...el, count: 0}))

            resources.forEach(el => {
                const found = aggregate.find(existing => existing.type === el.type && existing.subtype === el.subtype)
                if (found) {
                    found.count += 1
                } else {
                    aggregate.push({type: el.type, subtype: el.subtype, count: 1})
                }
            })

            return aggregate.sort((a, b) => b.count - a.count).slice(0, 4)
        },
    },
}
</script>

<style lang="scss">
.resources-card__nonscrollable {
    .b-table-opaque {
        overflow-x: hidden;
    }
}

.resources-card {
    min-height: 400px;

    .resources-card__img {
        height: 18px;
    }
}
</style>
