<template>
    <div v-if="activeProject">
        <b-row class="url-card__container-row">
            <b-col md="6" class="url-card__container-col">
                <b-card no-body class="url-card">
                    <b-card-header>Google Cloud Platform Resources</b-card-header>
                    <b-card-body>
                        <b-form-group label="Witcloud Dataset URL" :label-cols="4" :horizontal="true">
                            <a :href="datasetUrl" target="_BLANK" class="url-card__url-button">
                                <b-btn variant="primary">
                                    <span></span>
                                    <span>Open Dataset</span>
                                    <i class="fa fa-external-link" />
                                </b-btn>
                            </a>
                        </b-form-group>

                        <b-form-group label="Witcloud Bucket URL" :label-cols="4" :horizontal="true">
                            <a :href="bucketUrl" target="_BLANK" class="url-card__url-button">
                                <b-btn variant="primary">
                                    <span></span>
                                    <span>Open Bucket</span>
                                    <i class="fa fa-external-link" />
                                </b-btn>
                            </a>
                        </b-form-group>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col md="6" class="url-card__container-col">
                <b-card no-body class="docs-card">
                    <b-card-header>Explore Documentation</b-card-header>
                    <b-card-body>
                        <div class="docs-card__left">
                            <img src="@/assets/logotypes/doc_36.svg" />
                        </div>
                        <div class="docs-card__right">
                            <p>
                                Find instructions on how to use the platform with our handy sidebar or visit our full
                                wiki.
                            </p>
                            <div class="docs-card__buttons">
                                <b-button variant="primary" @click="toggleDocs">Open Sidebar</b-button>
                                <a href="https://witbee.com/docs/start/" target="_BLANK">
                                    <b-button variant="primary">
                                        <span>Open Docs Page</span>
                                        <i class="fa fa-external-link" />
                                    </b-button>
                                </a>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import URL from '@/components/URL.vue'
import eventBus from '../../eventBus'

export default {
    components: {
        URL,
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        datasetUrl() {
            return `https://console.cloud.google.com/bigquery?project=${this.activeProject.projectId}&p=${
                this.activeProject.projectId
            }&d=witcloud_${this.activeProject.id.toLowerCase()}&page=dataset`
        },
        bucketUrl() {
            return `https://console.cloud.google.com/storage/browser/witcloud-st-${this.activeProject.id.toLowerCase()}?forceOnBucketsSortingFiltering=false&project=${
                this.activeProject.projectId
            }`
        },
    },
    methods: {
        toggleDocs() {
            eventBus.$emit('toggleHelp')
        },
    },
}
</script>

<style lang="scss">
.url-card {
    height: calc(100% - 1.5rem);

    .card-body {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .col-form-label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.75rem;
        color: #7f7f7f;
    }

    .form-row {
        align-items: center;
    }

    a.url-card__url-button {
        width: calc(min(90%, 300px));
        display: inline-block;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        button {
            width: 100%;
            font-weight: 600;
            font-size: 0.75rem;
            border-radius: 8px;
            padding: 0.375rem 0.95rem;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            img {
                height: 20px;
            }

            span {
                padding: 0 5px;
                text-transform: uppercase;
            }
        }
    }
}

.docs-card {
    .card-body {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
            display: block;
            height: 100px;
            margin-right: 25px;
        }

        .docs-card__buttons {
            button {
                margin-right: 5px;

                i {
                    margin-left: 5px;
                }
            }
        }
    }
}

.url-card__container-col {
    flex-grow: 1;
}
</style>
