export const dashboardMixin = {
    data() {
        return {
            cancelSource: this.axios.CancelToken.source(),
        }
    },
    beforeDestroy() {
        try {
            this.cancelSource.cancel('cancelled')
        } catch {}
    },
}
