<template>
    <div v-intersect="{onChange: handleIntersection}">
        <b-card no-body class="stats-card">
            <b-card-header>WitCloud Statistics</b-card-header>
            <b-card-body
                ><div class="stats-filter-row">
                    <div class="stats-filter-row__col stats-filter-row__col-buttons">
                        <span v-b-tooltip.hover title="Refresh">
                            <i @click="fetchStats" class="icon-reload stats-filter-row__icon pointer-action" /> </span
                        ><span v-b-tooltip.hover title="Clear filters">
                            <i @click="clearFilters" class="icon-close stats-filter-row__icon pointer-action" />
                        </span>
                    </div>
                    <div class="stats-filter-row__col stats-filter-row__col-flex">
                        <DateRangePicker
                            ref="picker"
                            :appendToBody="true"
                            opens="left"
                            v-model="dateRange"
                            @update="fetchStats"
                            class="stats-filter-row__daterange-picker"
                        ></DateRangePicker>
                    </div>
                </div>
                <div class="stats-filter-row">
                    <div class="stats-filter-row__col">
                        <b-input
                            v-model="stats.filters.resourceName"
                            placeholder="Filter by name"
                            size="sm"
                            class="stats-filter-row__resource-name"
                            v-b-tooltip.hover
                            title="You can use a Regular Expression"
                            @input="fetchStatsTimeout"
                            v-hotkey="keymap"
                        />
                    </div>
                    <div class="stats-filter-row__col stats-filter-row__col-treeselect">
                        <treeselect
                            :multiple="true"
                            valueFormat="id"
                            valueConsistsOf="LEAF_PRIORITY"
                            :options="stats.types"
                            v-model="stats.filters.resourceTypes"
                            @input="fetchStatsTimeout"
                            :searchable="false"
                            placeholder="Filter by type"
                            class="stats-filter-row__resource-type treeselect--sm"
                        ></treeselect>
                    </div>
                    <div class="stats-filter-row__col stats-filter-row__col-treeselect">
                        <treeselect
                            :multiple="true"
                            valueFormat="id"
                            valueConsistsOf="LEAF_PRIORITY"
                            :options="stats.subtypes"
                            v-model="stats.filters.resourceSubtypes"
                            @input="fetchStatsTimeout"
                            :searchable="false"
                            placeholder="Filter by subtype"
                            class="stats-filter-row__resource-subtype treeselect--sm"
                        ></treeselect>
                    </div>
                    <div class="stats-filter-row__col stats-filter-row__col-treeselect">
                        <treeselect
                            :multiple="true"
                            valueFormat="object"
                            valueConsistsOf="LEAF_PRIORITY"
                            :options="stats.selectOptions"
                            v-model="stats.selects"
                            @input="fetchStatsTimeout"
                            :searchable="false"
                            placeholder="Columns"
                            class="treeselect--sm"
                        ></treeselect>
                    </div>
                </div>
                <div class="stats-filter-row">
                    <div class="stats-filter-row__col stats-filter-row__col-unit">
                        <div class="stats-filter-row__unit-wrapper">
                            Show data processed in
                            <wit-select
                                v-model="graph.unit"
                                placeholder="Unit"
                                size="sm"
                                inline
                                class="stats-filter-row__unit"
                                :options="graph.unitOptions"
                                :allow-empty="false"
                            />
                        </div>
                    </div>
                </div>

                <CChartBar
                    class="stats-chart-bar"
                    :datasets="chartDatasets"
                    :labels="chartLabels"
                    :options="graph.options"
                    ref="chartRef"
                />

                <wit-table
                    :items="stats.items"
                    :fields="stats.fields"
                    :striped="true"
                    paginated
                    :default-per-page="10"
                    :show-per-page-select="true"
                    :busy="stats.busy"
                >
                    <template v-slot:cell(totalBytesProcessed)="{item}">
                        {{ parseBytesToString(item.totalBytesProcessed) }}
                    </template>

                    <template v-slot:custom-foot v-if="stats.items.length > 1 && !stats.busy">
                        <b-tr>
                            <b-th v-if="stats.fields.length > 3" :colspan="stats.fields.length - 3" />
                            <b-th colspan="1">
                                Total
                            </b-th>
                            <b-th colspan="1">
                                {{ parseBytesToString(sumTotalBytesProcessed) }}
                            </b-th>
                            <b-th colspan="1">
                                {{ sumExecutions }}
                            </b-th>
                        </b-tr>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue'
import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)

import {mapGetters} from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {CChartBar} from '@coreui/vue-chartjs'
import {IntersectDirective} from 'vue-intersect-directive'

import WitTable from '@/components/WitTable.vue'

import {fromEntries} from '@/shared/fromEntries'

import {dashboardMixin} from '@/mixins/dashboardMixin'

const _randomColor = require('randomcolor')
const d3 = require('d3-time')
const d3format = require('d3-time-format')

export default {
    components: {
        CChartBar,
        DateRangePicker,
        Treeselect,
        WitTable,
    },
    directives: {
        intersect: IntersectDirective,
    },
    mixins: [dashboardMixin],
    data() {
        return {
            dateRange: {
                startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 12, 0, 0),
                endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 12, 0, 0),
            },
            stats: {
                items: [],
                fields: [
                    'date',
                    'month',
                    'resourceName',
                    'resourceType',
                    'resourceSubtype',
                    'totalBytesProcessed',
                    'executions',
                ],
                selects: [
                    {id: 'date', label: 'Date'},
                    {id: 'resourceType', label: 'Type'},
                    {id: 'resourceSubtype', label: 'Subtype'},
                ],
                selectOptions: [
                    {id: 'date', label: 'Date'},
                    {id: 'month', label: 'Month'},
                    {id: 'resourceName', label: 'Name'},
                    {id: 'resourceType', label: 'Type'},
                    {id: 'resourceSubtype', label: 'Subtype'},
                ],
                filters: {
                    resourceName: null,
                    resourceTypes: null,
                    resourceSubtypes: null,
                },
                types: [],
                subtypes: [],
                busy: false,
            },
            fetchTimeout: null,
            graph: {
                options: {
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                            },
                        ],
                        yAxes: [
                            {
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: `[GB]`,
                                },
                            },
                        ],
                    },
                    maintainAspectRatio: false,
                },
                unit: 'GB',
                unitOptions: ['B', 'kB', 'MB', 'GB', 'TB'],
                randomColorHue: '#20a8d8',
            },
            requested: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        keymap() {
            return {
                enter: this.fetchStats,
            }
        },
        sumTotalBytesProcessed() {
            return this.stats.items.reduce((acc, curr) => acc + curr.totalBytesProcessed, 0)
        },
        sumExecutions() {
            return this.stats.items.reduce((acc, curr) => acc + curr.executions, 0)
        },
        resourcesAggregate() {
            const resourcesAggregate = []

            this.stats.items.forEach(item => {
                const key = [item.resourceName, item.resourceType, item.resourceSubtype].filter(Boolean).join(' - ')

                const found = resourcesAggregate.find(el => el.key === key)
                if (found) {
                    found.values.push({
                        date: item.date,
                        month: item.month,
                        totalBytesProcessed: item.totalBytesProcessed,
                    })
                } else {
                    resourcesAggregate.push({
                        key,
                        resourceName: item.resourceName,
                        resourceType: item.resourceType,
                        resourceSubtype: item.resourceSubtype,
                        values: [{date: item.date, month: item.month, totalBytesProcessed: item.totalBytesProcessed}],
                    })
                }
            })

            return resourcesAggregate
        },
        chartDates() {
            if (
                d3format.timeFormat('%Y-%m-%d')(this.dateRange.startDate) ===
                d3format.timeFormat('%Y-%m-%d')(this.dateRange.endDate)
            ) {
                return [d3format.timeFormat('%Y-%m-%d')(this.dateRange.startDate)]
            }

            return d3.timeDay
                .range(d3.timeDay.floor(this.dateRange.startDate), d3.timeDay.ceil(this.dateRange.endDate))
                .map(el => d3format.timeFormat('%Y-%m-%d')(el))
        },
        chartMonths() {
            return d3.timeMonth
                .range(d3.timeMonth.floor(this.dateRange.startDate), d3.timeMonth.ceil(this.dateRange.endDate))
                .map(el => d3format.timeFormat('%Y-%m')(el))
        },
        chartLabels() {
            if (!this.stats.fields.includes('month') && !this.stats.fields.includes('date')) {
                return this.stats.items.map(item =>
                    [item.resourceName, item.resourceType, item.resourceSubtype].filter(Boolean).join(' - ')
                )
            } else if (this.stats.fields.includes('date')) {
                return this.chartDates
            } else if (this.stats.fields.includes('month')) {
                return this.chartMonths
            }
        },
        chartDatasets() {
            if (!this.stats.fields.includes('month') && !this.stats.fields.includes('date')) {
                const color = this.randomColor()
                const unit = this.graph.unit === 'B' ? 'Bytes' : this.graph.unit

                return [
                    {
                        data: this.stats.items.map(el => this.parseBytes(el.totalBytesProcessed)),
                        backgroundColor: color,
                        label: `Total ${unit} Processed`,
                        minBarLength: 2,
                    },
                ]
            }
            if (this.stats.fields.includes('date')) {
                const color = this.randomColor({count: this.resourcesAggregate.length})

                return this.resourcesAggregate.map((item, index) => ({
                    data: this.chartDates.map(date => {
                        const valueFound = item.values.find(value => value.date === date)
                        return valueFound ? this.parseBytes(valueFound.totalBytesProcessed) : 0
                    }),
                    backgroundColor: color[index],
                    label: item.key,
                }))
            }
            if (this.stats.fields.includes('month')) {
                const color = this.randomColor({count: this.resourcesAggregate.length})

                return this.resourcesAggregate.map((item, index) => ({
                    data: this.chartMonths.map(month => {
                        const valueFound = item.values.find(value => value.month === month)
                        return valueFound ? this.parseBytes(valueFound.totalBytesProcessed) : 0
                    }),
                    backgroundColor: color[index],
                    label: item.key,
                }))
            }
        },
    },
    watch: {
        'graph.unit': {
            deep: true,
            handler(value) {
                this.graph.options.scales.yAxes[0].scaleLabel.labelString = `[${value}]`
            },
        },
    },
    created() {
        try {
            const {filters, selects} = JSON.parse(localStorage.getItem('dashboardStatisticsConfig'))
            this.stats.filters = filters
            this.stats.selects = selects || []
        } catch {
            localStorage.removeItem('dashboardStatisticsConfig')
        }
    },
    mounted() {
        this.mounted = true
    },
    methods: {
        async handleIntersection(value) {
            if (value && !this.requested && this.mounted) {
                await this.fetchStats()
            }
        },

        async fetchStats() {
            try {
                this.stats.busy = true
                this.requested = true

                if (this.fetchTimeout) {
                    clearTimeout(this.fetchTimeout)
                    this.fetchTimeout = null
                }

                const statsRequest = this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/dashboard/getStats`,
                    {
                        witcloudProjectId: this.activeProject.id,
                        selects: fromEntries(this.stats.selects.map(select => [select.id, true])),
                        filters: fromEntries(Object.entries(this.stats.filters).filter(([key, value]) => value)),
                        startDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.startDate),
                        endDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.endDate),
                    },
                    {
                        cancelToken: this.cancelSource.token,
                    }
                )
                const typesRequest = this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/dashboard/getTypes`,
                    {
                        witcloudProjectId: this.activeProject.id,
                        startDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.startDate),
                        endDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.endDate),
                    },
                    {
                        cancelToken: this.cancelSource.token,
                    }
                )
                const subtypesRequest = this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/dashboard/getSubtypes`,
                    {
                        witcloudProjectId: this.activeProject.id,
                        startDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.startDate),
                        endDate: d3format.timeFormat('%Y-%m-%d')(this.dateRange.endDate),
                    },
                    {
                        cancelToken: this.cancelSource.token,
                    }
                )

                const [statsResponse, typesResponse, subtypesResponse] = await Promise.all([
                    statsRequest,
                    typesRequest,
                    subtypesRequest,
                ])

                this.stats.items = statsResponse.data.data.map(row => {
                    return {
                        ...row,
                        totalBytesProcessed: row.totalBytesProcessed ? row.totalBytesProcessed : 0,
                    }
                })

                this.stats.fields = [
                    this.stats.selects.find(el => el.id === 'date') ? 'date' : null,
                    this.stats.selects.find(el => el.id === 'month') ? 'month' : null,
                    this.stats.selects.find(el => el.id === 'resourceName') ? 'resourceName' : null,
                    this.stats.selects.find(el => el.id === 'resourceType') ? 'resourceType' : null,
                    this.stats.selects.find(el => el.id === 'resourceSubtype') ? 'resourceSubtype' : null,
                    'totalBytesProcessed',
                    'executions',
                ].filter(Boolean)

                this.stats.types = typesResponse.data.data.map(el => ({
                    id: el,
                    label: el,
                }))
                this.stats.subtypes = subtypesResponse.data.data.map(el => ({
                    id: el,
                    label: el,
                }))

                localStorage.setItem(
                    'dashboardStatisticsConfig',
                    JSON.stringify({
                        selects: this.stats.selects,
                        filters: this.stats.filters,
                    })
                )

                this.stats.busy = false
            } catch (e) {
                if (this.axios.isCancel(e)) {
                    return
                } else {
                    this.stats.busy = false
                    this.$errorHandler.report(e, 'Could not fetch statistics')
                }
            }
        },

        clearFilters() {
            this.stats.filters = {
                resourceName: null,
                resourceType: null,
                resourceSubtype: null,
            }
            this.stats.types = []
            this.stats.subtypes = []
            this.stats.selects = [
                {id: 'resourceName', label: 'Name'},
                {id: 'resourceType', label: 'Type'},
                {id: 'resourceSubtype', label: 'Subtype'},
            ]
            this.dateRange = {
                startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 12, 0, 0),
                endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 12, 0, 0),
            }

            this.fetchStats()
        },

        fetchStatsTimeout() {
            if (this.fetchTimeout) {
                clearTimeout(this.fetchTimeout)
                this.fetchTimeout = null
            }
            this.fetchTimeout = setTimeout(() => this.fetchStats(), 1500)
        },

        parseBytesToString(bytes) {
            if (bytes === 0) return '0 B'
            const prefixes = ['', 'k', 'M', 'G', 'T', 'P']
            const [_bytes] = prefixes
                .map((el, index) => ({size: bytes / Math.pow(1024, index), prefix: el}))
                .filter(el => el.size >= 1)
                .sort((a, b) => a.size - b.size)

            return `${_bytes.size.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })} ${_bytes.prefix}B`
        },

        parseBytes(bytes) {
            if (bytes === 0) return 0

            const _bytes = this.graph.unitOptions
                .map((el, index) => ({size: bytes / Math.pow(1024, index), prefix: el}))
                .find(el => el.prefix === this.graph.unit)

            const getFractionDigits = (bytes, digits) => {
                if (digits >= 20) {
                    return 20
                }

                const size = bytes.toFixed(digits)

                return size > 0 ? digits : getFractionDigits(bytes, digits + 2)
            }

            const fractionDigits = _bytes.size >= 1 ? 2 : getFractionDigits(_bytes.size, 2)
            return _bytes.size.toFixed(fractionDigits)
        },

        randomColor(options) {
            return _randomColor({
                hue: this.graph.randomColorHue,
                seed: 'witcloud',
                ...options,
            })
        },
    },
}
</script>

<style></style>
